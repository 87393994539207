import React, { useEffect, useState } from 'react'
import ApexCharts from 'apexcharts'


const SebaranAsalSekolah = () => {
    const [chart, setChart] = useState(null)
    let options = {
        chart: {
          type: 'bar',
          stacked: true,
        },
        series: [],            
        xaxis: {
            categories: [],
        },
        plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                total: {
                  enabled: true,
                  offsetX: 0,
                  style: {
                    fontSize: '13px',
                    fontWeight: 900
                  }
                }
              }
            },
          },            
    }
    useEffect(() => {

        const fetchData = async () => {
            const response = await fetch('https://pameran.apps.unismuh.ac.id/peserta.php?aksi=sebaran_sekolah')
            const data = await response.json()
            console.log(data);
            options.series = data.masterdata.series
            options.xaxis.categories = data.masterdata.categories
            setChart(options)
        }
     
          
        const asyncChart = async () => {
            await fetchData()
            const chart = new ApexCharts(document.querySelector("#chart"), options);
            setChart(chart)
            await chart.render();
            setInterval(async() => {
                await fetchData()
                await chart.updateOptions(options)
            }, 10000)
        }
        asyncChart()
    }, [])
    
  return (
    <div className="col-md-12 col-lg-6">
        <div className="card" style={{ marginTop: "20px" }}>
            <div className="card-header">
                <h4>Sebaran Asal Sekolah (Top 10)</h4>
            </div>
            <div className="card-body">
                <div id="chart">
                    
                </div>
            </div>
        </div>
    </div>

  )
}

export default SebaranAsalSekolah
