// src/components/Undian.js

import React, { useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import '../counter.css';
import $$ from 'jquery';
import Swal from 'sweetalert2';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';


const Videotron = () => {
    const { camaba,pengunjung } = useParams()
    const navigate = useNavigate()
    const [kodePeserta, setKodePeserta] = useState({})
    const [bisaSimpan, setBisaSimpan] = useState(false)
    const [showSetting, setShowSetting] = useState(false)
    const [settingInput, setSettingInput] = useState({})
    const [masterdata, setMasterData] = useState({})
    let myHeaders;

    const pageStyle = {
        backgroundImage: settingInput ? `url('https://pameran.apps.unismuh.ac.id/uploads/videotron.png')` : 'url(videotron.png)',
        minHeight:'100vh',
        padding: '20px',
        backgroundSize: 'cover',
        display: 'flex',
        flexDirection: 'column',
    };

    const fetchData = () => {
        myHeaders = new Headers();
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };    
        fetch("https://pameran.apps.unismuh.ac.id/peserta.php?aksi=peserta", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log("awal",result);
            if (result.result === 'ok') {
                setMasterData(result)
                // setKodePeserta(result.masterdata[0])
                // console.log("ada");
            }
        })
        .catch(error => console.log('error', error));



    }

    const fetchSetting = () => {
        myHeaders = new Headers();
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };    
        fetch("https://pameran.apps.unismuh.ac.id/peserta.php?aksi=settingapp", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log(result);
            setSettingInput(result)
        })
        .catch(error => console.log('error', error));        
    }


    React.useEffect(() => {
        const intervalId = setInterval(() => fetchData(), 5000);
        fetchSetting();
        return () => clearInterval(intervalId);
    }, [])   


  return (
    <>
        
        <div 
            style={pageStyle} 
            className="container-fluid d-flex align-items-center justify-content-center"
        >
            <div className="row">
                <div className="col-md-6 mb-2">
                    <div className='pengunjung d-flex justify-content-center align-items-center'>
                        <div className="row">
                        <div className="col-12">
                            <h3 className="card-title" style={{fontSize: '7.5rem', color: 'white'}}>PMB</h3>
                            <h5 className="card-title" style={{fontSize: '5.5rem', color: 'white'}}>REAL COUNT</h5>
                        </div>
                        <div className="col-12">
                            <h3 className="card-title" style={{fontSize: '13.5rem', color: '#fd0'}}>{parseInt(masterdata?.undian?.total)}</h3>
                        </div>
                        <div className="col-12">
                            <h3 className="card-title" style={{fontSize: '5.5rem', color: 'white'}}>PENGUNJUNG</h3>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-2">
                    <div className='pengunjung d-flex justify-content-center align-items-center'>
                        <div className="row">
                        <div className="col-12">
                            <h3 className="card-title" style={{fontSize: '7.5rem', color: 'white'}}>PMB</h3>
                            <h5 className="card-title" style={{fontSize: '5.5rem', color: 'white'}}>REAL COUNT</h5>
                        </div>
                        <div className="col-12">
                            <h3 className="card-title" style={{fontSize: '13.5rem', color: '#fd0'}}>{parseInt(masterdata?.total)}</h3>
                        </div>
                        <div className="col-12">
                            <h3 className="card-title" style={{fontSize: '5.5rem', color: 'white'}}>PENDAFTAR</h3>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>

  );
};

export default Videotron;
